import React, { useEffect, useState } from 'react'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import CloseIcon from '@material-ui/icons/Close'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import List from '@material-ui/core/List'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import LinearProgress from '@material-ui/core/LinearProgress';
import { auth, storage, db, database } from '../../../database'
import { Link, useHistory } from 'react-router-dom'
import { useStateValue } from "../../StateProvider"
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});
function AdminBeds() {
  const [{ bedsData, }, dispatch] = useStateValue();
  const classes = useStyles();

  const history = useHistory()
  const [expand1Icon, setexpand1Icon] = useState(false)
  const [title, settitle] = useState('')
  const [desc, setdesc] = useState('')


  const [file, setfile] = useState(null)


  const [imageUrl, setimageUrl] = useState('')

  const [file2, setfile2] = useState(null)
  const [file3, setfile3] = useState(null)
  const [file4, setfile4] = useState(null)
  const [file5, setfile5] = useState(null)

  const [imageUrl2, setimageUrl2] = useState('')
  const [imageUrl3, setimageUrl3] = useState('')
  const [imageUrl4, setimageUrl4] = useState('')
  const [imageUrl5, setimageUrl5] = useState('')

  const [progress, setprogress] = useState('')
  const [progress2, setprogress2] = useState('')
  const [progress3, setprogress3] = useState('')
  const [progress4, setprogress4] = useState('')
  const [progress5, setprogress5] = useState('')



  const [section, setsection] = useState('')
  const [image1, setimage1] = useState('')
  const [image2, setimage2] = useState('')
  const [image3, setimage3] = useState('')
  const [bedcategory, setbedcategory] = useState('')
  const [dimensions, setdimensions] = useState('')
  const [oldrate, setoldrate] = useState('')
  const [newrate, setnewrate] = useState('')

  const [imagelink, setimagelink] = useState('')
  const [imagelink2, setimagelink2] = useState('')
  const [imagelink3, setimagelink3] = useState('')
  const [imagelink4, setimagelink4] = useState('')
  const [imagelink5, setimagelink5] = useState('')

  const [percent, setpercent] = useState('')
  const [route, setroute] = useState('')

  const [value, setvalue] = useState(0)

  useEffect(() => {

    var ADMIN = JSON.parse(localStorage.getItem('ADMIN'))
    if (!ADMIN) {
      history.push('/Admin')
    }



    var values1 = []
    database.ref(`beds`).once('value', (snap) => {
      var fetchData1 = snap.val()
      for (let keys in fetchData1) {
        values1.push({ ...fetchData1[keys], key: keys })
      }


      values1.sort((a, b) => a - b).reverse()

      dispatch({
        type: 'BEDS',
        payload: values1,
      })
    })


  }, [bedsData])



  const deleteAction = (key) => {

    var adminBeds = bedsData
    var newadminBeds = adminBeds.filter((item, i) => item.key !== key)
    dispatch({
      type: 'BEDS',
      payload: newadminBeds,
    })

    database
      .ref(`beds/${key}`)
      .remove()
      .then(() => {
        alert("successfully delete")

      }).catch((error) => {
        alert(error)
      });

  }



  const upload = () => {

    const uploadTask = storage.ref(`images/${file.name}`).put(file)
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        var uploadprogress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
        )
        setprogress(uploadprogress)
      },
      (error) => {
        console.log(error)
      },

      () => {
        storage
          .ref('images')
          .child(file.name)
          .getDownloadURL()
          .then((url) => {
            alert("Successfully upload")
            setimageUrl(url)
          })
      },
    )
  }

  const upload2 = () => {

    const uploadTask = storage.ref(`images/${file2.name}`).put(file2)
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        var uploadprogress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
        )
        setprogress2(uploadprogress)
      },
      (error) => {
        console.log(error)
      },

      () => {
        storage
          .ref('images')
          .child(file2.name)
          .getDownloadURL()
          .then((url) => {
            alert("Successfully upload")
            setimageUrl2(url)
          })
      },
    )
  }

  const upload3 = () => {

    const uploadTask = storage.ref(`images/${file3.name}`).put(file3)
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        var uploadprogress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
        )
        setprogress3(uploadprogress)
      },
      (error) => {
        console.log(error)
      },

      () => {
        storage
          .ref('images')
          .child(file3.name)
          .getDownloadURL()
          .then((url) => {
            alert("Successfully upload")
            setimageUrl3(url)
          })
      },
    )
  }


  const upload4 = () => {

    const uploadTask = storage.ref(`images/${file4.name}`).put(file4)
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        var uploadprogress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
        )
        setprogress4(uploadprogress)
      },
      (error) => {
        console.log(error)
      },

      () => {
        storage
          .ref('images')
          .child(file4.name)
          .getDownloadURL()
          .then((url) => {
            alert("Successfully upload")
            setimageUrl4(url)
          })
      },
    )
  }

  const upload5 = () => {

    const uploadTask = storage.ref(`images/${file5.name}`).put(file5)
    uploadTask.on(
      'state_changed',
      (snapshot) => {
        var uploadprogress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
        )
        setprogress5(uploadprogress)
      },
      (error) => {
        console.log(error)
      },

      () => {
        storage
          .ref('images')
          .child(file5.name)
          .getDownloadURL()
          .then((url) => {
            alert("Successfully upload")
            setimageUrl5(url)
          })
      },
    )
  }









  const _handleClick = () => {
    var data = {
      title: title,
      descsectiontwo: desc,
      oldrate: oldrate,
      newrate: newrate,
      route: route,
      imagelink: imagelink,
      imagelink2: imagelink2,
      imagelink3: imagelink3,
      imagelink4: imagelink4,
      imagelink5: imagelink5,

      percent: percent,
      dimensions: dimensions,

      url: imageUrl,
      url2: imageUrl2,
      url3: imageUrl3,
      url4: imageUrl4,
      url5: imageUrl5,

      wishlist: false,
      bedcategory: bedcategory,



      imagelink: imagelink,
      percent: percent,






    }


    if (data.title === "") {
      alert("title field is required")
    }
   
    else {

      database
        .ref(`beds`)
        .push(data)
        .then(response => {
          alert("successfully upload Bed post")


          settitle('')
          setdesc('')
          setimageUrl('')

          var values = []
          database.ref(`beds`).once('value', (snap) => {
            var fetchData = snap.val()
            for (let keys in fetchData) {
              values.push({ ...fetchData[keys], key: keys })
            }
            // setsection4Data([section4Data, ...values])

            dispatch({
              type: "BEDS",
              payload: values,
            })

          })

          history.replace('/adminBeds')

        })
        .catch(error => {
          alert(error)
        });
    }

  }
  return (


    <div>
      <div className="section4__main">

        <div className="Section4__container">

          <p>Upload Beds</p>
          <select onChange={(event) => setbedcategory(event.target.value)} className="selectlist">
            <option>Select category</option>
            <option>Turkish beds</option>
            <option>Wooden beds</option>
            <option>Polish beds</option>
            <option>Deco beds</option>
          </select>

          <h5>Title</h5>
          <input value={title} type="text" onChange={(e) => settitle(e.target.value)} />


          <h5>Description</h5>
          <input value={desc} type="text" onChange={(e) => setdesc(e.target.value)} />

          <h5>Dimensions</h5>
          <input value={dimensions} type="text" onChange={(e) => setdimensions(e.target.value)} />


          <h5>old Rate</h5>
          <input
            type="text"
            onChange={(e) => setoldrate(e.target.value)}
          />
          <h5>new rate</h5>
          <input
            value={newrate}
            type="text"
            onChange={(e) => setnewrate(e.target.value)}
          />


          <h5>route name</h5>
          <input type="text" onChange={(e) => setroute(e.target.value)} />

          <h5>discount percent</h5>
          <input type="text" onChange={(e) => setpercent(e.target.value)} />

          <h5>image link</h5>
          <input type="text" onChange={(e) => setimagelink(e.target.value)} />
          <h5>image link 2</h5>
          <input type="text" onChange={(e) => setimagelink2(e.target.value)} />
          <h5>image link 3</h5>
          <input type="text" onChange={(e) => setimagelink3(e.target.value)} />
          <h5>image link 4</h5>
          <input type="text" onChange={(e) => setimagelink4(e.target.value)} />
          <h5>image link 5</h5>
          <input type="text" onChange={(e) => setimagelink5(e.target.value)} />

          <h5>Upload Image </h5>
          <input
            type="file"
            onChange={(e) => setfile(e.target.files[0])}
          />

          <button
            onClick={upload}
            className="btn btn-success mx-auto"
          >
            upload image
          </button>


          <h5>Upload Image 2 </h5>
          <input
            type="file"
            onChange={(e) => setfile2(e.target.files[0])}
          />

          <button
            onClick={upload2}
            className="btn btn-success mx-auto"
          >
            upload image 2
          </button>

          <h5>Upload Image 3 </h5>
          <input
            type="file"
            onChange={(e) => setfile3(e.target.files[0])}
          />

          <button
            onClick={upload3}
            className="btn btn-success mx-auto"
          >
            upload image 3
          </button>


          <h5>Upload Image 4 </h5>
          <input
            type="file"
            onChange={(e) => setfile4(e.target.files[0])}
          />

          <button
            onClick={upload4}
            className="btn btn-success mx-auto"
          >
            upload image 4
          </button>


          <h5>Upload Image 5 </h5>
          <input
            type="file"
            onChange={(e) => setfile5(e.target.files[0])}
          />

          <button
            onClick={upload5}
            className="btn btn-success mx-auto"
          >
            upload image 5
          </button>





          <div style={{ marginTop: 20 }}>
            <LinearProgress variant="determinate" value={progress} color="secondary" />
          </div>

          <button onClick={_handleClick} className="login__signInButton">
            SUBMIT
          </button>
        </div>






        <TableContainer style={{ marginTop: "5%" }} component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow >
                <TableCell align="right">Title</TableCell>
                <TableCell align="right">Rate</TableCell>
                <TableCell align="right">image</TableCell>
                <TableCell align="right">Desc</TableCell>
                <TableCell align="center">Product</TableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {bedsData && bedsData.map((item, i) => (
                <TableRow key={i} style={{ cursor: "pointer" }}>

                  <TableCell align="right">
                    {item.title}
                  </TableCell>
                  <TableCell align="right">{item.rate}</TableCell>
                  <TableCell align="right"><img src={item.url} width="30%"/></TableCell>
                  <TableCell align="right">{item.descsectiontwo}</TableCell>
                  <TableCell align="center" ><img width="20%" src={item.imagelink} /></TableCell>
                  <Button onClick={() => deleteAction(item.key)} style={{ marginTop: "10%" }} variant="contained" color="secondary">
                    Delete
                  </Button>

                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  )
}

export default AdminBeds
