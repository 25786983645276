// For Firebase JS SDK v7.20.0 and later, measurementId is optional

import firebase from "firebase";


const firebaseConfig = {

    apiKey: "AIzaSyCp-Od9rqGV-h6xZ8-47Tb8CPSlGMykSCQ",
    authDomain: "iconwoodapplication.firebaseapp.com",
    databaseURL: "https://iconwoodapplication-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "iconwoodapplication",
    storageBucket: "iconwoodapplication.appspot.com",
    messagingSenderId: "992134445649",
    appId: "1:992134445649:web:25aaa069ca80d24a765670"
    
  };

  const firebaseApp = firebase.initializeApp(firebaseConfig);

  const db = firebaseApp.firestore();
  const auth = firebase.auth();
  const storage = firebase.storage();
  const  database = firebase.database()
  
  export { db, auth,storage,database };





  