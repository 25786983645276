import React from 'react'
import "./userorders.css"
import { useEffect, useState } from 'react'
import { useStateValue } from '../StateProvider'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { BackServer } from "../Services"

import axios from 'axios'
import { BorderBottom } from '@material-ui/icons'


function UserOrders() {
    const [{ Userorders, users }, dispatch] = useStateValue()
    const [orderstatus, setorderstatus] = useState('Pending')



    useEffect(() => {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0


    }, [])


    useEffect(() => {

        // var usersfromlocalstorage = JSON.parse(
        //     localStorage.getItem('users'),
        // )
        if (users) {

            axios
                .post(`${BackServer}/data/getUserOrders/${users._id}`)
                .then((res) => {
                    dispatch({
                        type: "SHOPNOW",
                        payload: res.data.order
                    })
                })
        }
    }, [Userorders])


    return (

        <div style={{ display: "flex" }}>



            <div className="leftSide" style={{ paddingTop: "0px", paddingBottom: "100px", }}>

                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", padding: "20px", fontWeight: "bold" }}>
                    <div onClick={()=>setorderstatus('Pending')} style={{ width: "40%", display: "flex", justifyContent: "center", alignItems: "center", borderBottom: orderstatus === "Pending" && "1px solid grey", paddingBottom: "10px", fontSize: "13px" }}>
                        Pending Orders
                    </div>
                    <div onClick={()=>setorderstatus('delivered')} style={{ width: "40%", display: "flex", justifyContent: "center", alignItems: "center", borderBottom: orderstatus === 'delivered' && "1px solid grey", paddingBottom: "10px", fontSize: "13px" }}>
                        Delivered Orders
                    </div>
                </div>

                {Userorders && Userorders.length === 0 || !Userorders ? <div className="whishlistEmpty" id="MontserratSemiBold" style={{ height: "700px", backgroundColor: "#eeede9", color: "grey", justifyContent: "center", alignItems: "center", display: "flex", width: "100%" }}><h3>No Orders</h3></div>
                    :
                    Userorders && Userorders.map((item, i) => {
                        return (
                          orderstatus === "Pending" && item.status === "Pending" &&
                            <div style={{ marginBottom: "100px", marginTop: "50px" }} class="card">
                                <div class="title2">Purchase Reciept</div>
                                <div class="info">
                                    <div class="row">
                                        <div class="col-7"> <span id="heading">Date</span><br /> <span id="details">{item.orderDate}</span> </div>
                                        <div class="col-5 pull-right"> <span id="heading">Tracking Id.</span><br /> <span id="details">{item.trackingid}</span> </div>
                                    </div>

                                    <div class="col-2"><img class="img-fluid" src={item.imagelink} /></div>

                                </div>
                                <div class="pricing">
                                    <div class="row">
                                        <div class="col-9"> <span id="name">{item.descsectiontwo}</span> </div>
                                        <div class="col-3"> <span id="price">{item.newrate}</span> </div>

                                    </div>
                                    <div class="row">
                                        <div class="col-9"> <span id="name">Status</span> </div>
                                        <div class="col-3"> <span id="price">{item.status}</span> </div>
                                    </div>
                                </div>
                                <div class="total">
                                    <div class="row">
                                        <div class="col-9"></div>
                                        <div class="col-3"><big>{item.rate}</big></div>
                                    </div>
                                </div>
                                <div class="tracking">
                                    <div class="title2">Tracking Order</div>
                                </div>
                                <div class="progress-track">
                                    <ul id="progressbar">
                                        <li class="step0 active " id="step1">Ordered</li>
                                        <li class="step0 active text-center" id="step2">Shipped</li>
                                        <li class="step0 active text-right" id="step3">On the way</li>
                                        <li id={item.status === "delivered" && "step4"} class="step0 text-right" >Delivered {item.status === "delivered" && item.deliveredDate}</li>
                                    </ul>
                                </div>
                                <div class="footer">
                                    <div class="row">
                                        <div class="col-2"><img class="img-fluid" src="https://i.imgur.com/YBWc55P.png" /></div>
                                        <div class="col-10">Want any help? Please &nbsp;<a href="https://iconwood.com.pk/contact"> contact us</a></div>
                                    </div>
                                </div>
                            </div>

                        )
                    })
                }


                {Userorders && Userorders.length === 0 || !Userorders ? <div className="whishlistEmpty" id="MontserratSemiBold" style={{ height: "700px", backgroundColor: "#eeede9", color: "grey", justifyContent: "center", alignItems: "center", display: "flex", width: "100%" }}><h3>No Orders</h3></div>
                    :
                  orderstatus === 'delivered' &&  Userorders && Userorders.map((item, i) => {
                        return (
                            item.status === "delivered" &&
                            <div style={{ marginBottom: "100px", marginTop: "50px" }} class="card">
                                <div class="title2">Purchase Reciept</div>
                                <div class="info">
                                    <div class="row">
                                        <div class="col-7"> <span id="heading">Date</span><br /> <span id="details">{item.orderDate}</span> </div>
                                        <div class="col-5 pull-right"> <span id="heading">Tracking Id.</span><br /> <span id="details">{item.trackingid}</span> </div>
                                    </div>

                                    <div class="col-2"><img class="img-fluid" src={item.imagelink} /></div>

                                </div>
                                <div class="pricing">
                                    <div class="row">
                                        <div class="col-9"> <span id="name">{item.descsectiontwo}</span> </div>
                                        <div class="col-3"> <span id="price">{item.newrate}</span> </div>

                                    </div>
                                    <div class="row">
                                        <div class="col-9"> <span id="name">Status</span> </div>
                                        <div class="col-3"> <span id="price">{item.status}</span> </div>
                                    </div>
                                </div>
                                <div class="total">
                                    <div class="row">
                                        <div class="col-9"></div>
                                        <div class="col-3"><big>{item.rate}</big></div>
                                    </div>
                                </div>
                                <div class="tracking">
                                    <div class="title2">Tracking Order</div>
                                </div>
                                <div class="progress-track">
                                    <ul id="progressbar">
                                        <li class="step0 active " id="step1">Ordered</li>
                                        <li class="step0 active text-center" id="step2">Shipped</li>
                                        <li class="step0 active text-right" id="step3">On the way</li>
                                        <li id={item.status === "delivered" && "step4"} class="step0 text-right" >Delivered {item.status === "delivered" && item.deliveredDate}</li>
                                    </ul>
                                </div>
                                <div class="footer">
                                    <div class="row">
                                        <div class="col-2"><img class="img-fluid" src="https://i.imgur.com/YBWc55P.png" /></div>
                                        <div class="col-10">Want any help? Please &nbsp;<a href="https://iconwood.com.pk/contact"> contact us</a></div>
                                    </div>
                                </div>
                            </div>

                        )
                    })
                }



            </div>


        </div>


    )
}

export default UserOrders
