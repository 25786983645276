  
// var Server ="http://192.168.1.102:3000"

// var Server ="http://localhost:3000"


var Server = "https://iconwood.com.pk"


// var BackServer ="http://localhost:8080"
var BackServer ="https://iconwood.herokuapp.com"

export {Server,BackServer}

