
import React, { useEffect, useState } from 'react'
import ReactWhatsapp from 'react-whatsapp';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import BuildIcon from '@material-ui/icons/Build';
import { Link, useHistory, useLocation } from 'react-router-dom'
 import Businessprofile from "./businessprofile.pdf"
const HiddenNavbar = () => {
  const history = useHistory()

    const [show, setShow] = useState(true)
    const controlNavbar = () => {
        if (window.scrollY > 5) {
            setShow(false)
        } else {
            setShow(true)
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', controlNavbar)
        return () => {
            window.removeEventListener('scroll', controlNavbar)
        }
    }, [])

    const onButtonClick = () => {
        // using Java Script method to get PDF file
        fetch(Businessprofile).then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = Businessprofile;
                alink.click();
            })
        })
    }
    return (
        <div className={`nav ${show && 'nav__blue'}`}>
            {
                show &&
                <div style={{ width: "100%" }}>

                    <div style={{ width: "100%", backgroundColor: "#fff0b5", height: "50px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div style={{ color: "red", fontWeight: "bold", fontSize: "13px" }}>
                            Buy online and get an additional upto 15% off
                        </div>

                    </div>
                    <div style={{ width: "100%", backgroundColor: "white", height: "50px", display: "flex", borderBottom: "2px solid #e9e9e9", }}>

                        <div onClick={()=>history.push('/contact')} style={{ width: "33%", display: "flex", justifyContent: "center", alignItems: "center", borderRight: "2px solid #e9e9e9", fontWeight: "bold", fontSize: "14px" }}>
                            Contact
                        </div>
                        
                        <button onClick={onButtonClick} style={{ width: "33%", display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "bold", fontSize: "14px", borderRight: "2px solid #e9e9e9" }}>
                            Business Profile
                        </button>
                        <div style={{ width: "33%", display: "flex", justifyContent: "center", alignItems: "center", borderRight: "2px solid #e9e9e9", fontWeight: "bold", fontSize: "14px" }}>
                            <div style={{backgroundColor:"green",width:"35px",height:"35px",display:"flex",justifyContent:"center",alignItems:"center",borderRadius:"50px"}}>
                                <a href="https://wa.me/03312380673"><WhatsAppIcon style={{ color: "white" }} /> </a>
                            </div>
                        </div>

                    </div>

                </div>

            }
        </div>


    )
}

export default HiddenNavbar