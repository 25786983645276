import React, { useState, useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Drawer from '@material-ui/core/Drawer';
import HiddenNavbar from "./hiddenNavbar"
import Typed from "react-typed";
import logo from "../../../assets/icons/logo.png"
import logo2 from "../../../assets/icons/logo2.png"
import logo3 from "../../../assets/icons/logo4.png"
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import CloseIcon from '@material-ui/icons/Close';
import BuildIcon from '@material-ui/icons/Build';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Divider from '@material-ui/core/Divider';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'



// import LogoutIcon from '@material-ui/icons//Logout';
// import LogoutIcon from '@material-ui/icons/Logout';
import { Link, useHistory, useLocation } from 'react-router-dom'
import { FiLogOut } from 'react-icons/fi';
import { useStateValue } from '../../StateProvider'
import { database } from '../../../database'
import styled from "styled-components";
import Media from "../../../shared-components/media"
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import ShopIcon from '@material-ui/icons/Shop';

const useStyles = makeStyles({
  root: {
    width: "100%",
    position: "fixed",
    bottom: "0%",
  },
  list: {
    width: 340,
  },
  fullList: {
    width: "auto",
  },
});


function MobileTopBar() {
  const classes = useStyles()
  const history = useHistory()
  const [{ wishlist, Userorders, users,pendingorderlength }, dispatch] = useStateValue();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [expand1Icon, setExpand1Icon] = useState(false);
  const [expand2Icon, setExpand2Icon] = useState(false);
  const [value2, setValue2] = useState(1);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    if (open) {
      setDrawerOpen(true);
    }

    else {
      setDrawerOpen(false);
    }

    setState({ ...state, [anchor]: open });
  };

  const handleClick = (value, links, fff) => {
    setDrawerOpen(value);
    setExpand1Icon(fff)
    setState({ ...state, left: value });
    if (links) {
      history.push(links)

    }


  }
  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"

    >
      <List style={{ padding: 10 }}>


        <ListItem button style={{ backgroundColor: '#fff0b5', height: 55 }}>

          <ListItemText style={{ color: "#ffffff" }}>
            {users !== null ?
              <div style={{ color: "black" }} onClick={() => handleClick(false, "/user_orders")}>
                <ShopIcon style={{ color: "black" }} />
                <span style={{margin:"5px"}}>
                  Your orders 
                </span>
              </div>
              :
              <div style={{ fontSize: "12px", color: "black", fontWeight: "bold" }}>ICONWOOD By Al Madina Furniture</div>
            }
          </ListItemText>
          <ListItemIcon ><CloseIcon onClick={() => handleClick(false, null)} style={{ fontSize: 20, color: "black", marginLeft: "39px", fontSize: "17px" }} /></ListItemIcon>
        </ListItem>
        <ListItem onClick={() => window.open('https://goo.gl/maps/JRizrc1KiGopp7nb7', '_blank')} button style={{ border: value2 === 1 && "2px solid #9f7757", marginTop: 6, height: 55 }}>
          <ListItemText style={{ color: "#9f7757" }}><span style={{ fontWeight: "bold", fontSize: "14px" }}>Find Location</span></ListItemText>
          <ListItemIcon><ArrowForwardIosIcon style={{ fontSize: 20, marginLeft: "39px", fontSize: "17px" }} /></ListItemIcon>
        </ListItem>
        <ExpansionPanel style={{ border: value2 === 2 && "2px solid #9f7757", marginTop: 10, boxShadow: "none" }} id="DrawerShoppingToolsMenu" onChange={(event, expanded) => { setExpand1Icon(expanded) }}>
          <ExpansionPanelSummary
            expandIcon={expand1Icon ? <RemoveIcon style={{ fontSize: 20, color: expand1Icon ? "#9f7757" : "" }} /> : <AddIcon style={{ fontSize: 20 }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <ListItemText><span style={{ fontWeight: "bold", fontSize: "12px", color: expand1Icon ? "#9f7757" : "" }}>Products</span></ListItemText>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>


            <div>

              <div onClick={() => handleClick(false, "/chairs")} className="productItems">
                Chairs

              </div>
              <div className="productItems">
                Beds
                <div onClick={() => handleClick(false, "/woodenbeds")} style={{ margin: "8px" }}>- Wooden beds</div>
                <div onClick={() => handleClick(false, "/modernbeds")} style={{ margin: "8px" }}>- Modern beds</div>
                {/* <div onClick={() => handleClick(false, "/decobeds")} style={{ margin: "8px" }}>- Deco beds</div>
                <div onClick={() => handleClick(false, "/polishbed")} style={{ margin: "8px" }}>- polish beds</div> */}
              </div>

              <div className="productItems">
                Sofas
                <div onClick={() => handleClick(false, "/simplesofas")} style={{ margin: "8px" }}>- Modern Sofas</div>
                <div onClick={() => handleClick(false, "/woodensofas")} style={{ margin: "8px" }}>- Wooden Sofas</div>

              </div>

              <div onClick={() => handleClick(false, "/dinningstables")} className="productItems">Dininings</div>


              <div className="productItems">
                Center Tables
                <div onClick={() => handleClick(false, "/woodentables")} style={{ margin: "8px" }}>- Wooden Center Tables</div>
                <div onClick={() => handleClick(false, "/mssstables")} style={{ margin: "8px" }}>- SS / MS Center Tables</div>
                {/* <div onClick={() => handleClick(false, "/centerTables")} style={{ margin: "8px" }}>- All Center Tables</div> */}
              </div>

              <div onClick={() => handleClick(false, "/wardrobes")} className="productItems">Wardrobes</div>

              <div onClick={() => handleClick(false, "/swings")} className="productItems">Swings</div>

              {/* <div onClick={() => handleClick(false, "/calligraphy")} className="productItems">Calligraphy and Sceneries</div> */}



            </div>


          </ExpansionPanelDetails>
        </ExpansionPanel>
        <Divider />
        <ListItem onClick={() => handleClick(false, "/contact", 3)} button id="DrawerDodgeGarageMenu" style={{ marginTop: 10, border: value2 === 3 && "2px solid #9f7757" }}>
          <ListItemText><span style={{ fontWeight: "bold", fontSize: "12px" }}>Contact Us</span></ListItemText>
          <ListItemIcon><ArrowForwardIosIcon style={{ fontSize: 20, marginLeft: "39px", fontSize: "17px" }} /></ListItemIcon>
        </ListItem>

        <Divider />
        <ListItem onClick={() => handleClick(false, "/about", 4)} button style={{ marginTop: 10, border: value2 === 4 && "2px solid #9f7757" }}>
          <ListItemText><span style={{ fontWeight: "bold", fontSize: "12px" }}>About Us</span></ListItemText>
          <ListItemIcon><ArrowForwardIosIcon style={{ fontSize: 20, marginLeft: "39px", fontSize: "17px" }} /></ListItemIcon>
        </ListItem>
        <Divider />

      </List>

      <div onClick={() => handleClick(false, "/")} style={{ display: "flex", justifyContent: "center", paddingBottom: "0px", alignItems: "center", height: "100px", flexDirection: "column" }}>
        <img src={logo3} width="40%" />
        <span style={{color:"black",fontSize:"9px",fontWeight:"bold"}}>By Al Madina Furniture</span>

      </div>
    </div>
  );

  const signoutMethod = () => {
    localStorage.removeItem("users")

    dispatch({
      type: 'SET_USER',
      payload: null,
    })

    history.push('/')

  }


  return (

    <div >

      <MainWrapper className="MontserratSemiBold">

        <Left>
          <MenuIcon onClick={toggleDrawer("left", true)} style={{ color: "black" }} />
          <div>


            {['left'].map((anchor) => (
              <React.Fragment key={anchor}>

                <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                  {list(anchor)}
                </Drawer>
              </React.Fragment>
            ))}
          </div>

       
        </Left>
        <Mid>
          <img onClick={()=>history.push('/')} src={logo3} width="68%" />
            {/* <span style={{color:"black",fontSize:"9px",fontWeight:"bold"}}>By Al Madina Furniture</span> */}
        </Mid>

        <Right>


          {users === null ? (
            <div onClick={() => history.push('/')} style={{ color: "black" }}   >
              <PersonAddIcon />

            </div>

          ) : (


            <div onClick={signoutMethod} style={{ color: "black", fontSize: "18px" }} >
              <FiLogOut />
            </div>

          )}
          <MenuItem id="MontserratRegular" onClick={() => history.push("/wishlist")} >
            <ShoppingCartIcon style={{ fontSize: "20px", color: "black" }} />
            <ItemCounter>{wishlist === null ? 0 : wishlist.length}</ItemCounter>
          </MenuItem>
        </Right>
      </ MainWrapper>
      <HiddenNavbar />

    </div>

  )
}




const MainWrapper = styled.div`
width: 100%;
height: 60px;
background-color:white;
display: flex;
justify-content: center;
align-items: center;
color:white;
font-size: 30px;
${Media("mobile")} {
  width:100%;
font-size: 12px;
padding-top:10px;
padding-bottom:10px;

}
`;

const Left = styled.div`
width: 20%;
height: 60px;
justify-content:center;
display: flex;
align-items: center;
${Media("mobile")} {
  width:20%;

}
`;

const Mid = styled.div`
width: 55%;
height: 60px;
align-items: center;
justify-content:center;
flex-direction:column;
display: flex;
align-items: center;
${Media("mobile")} {
  width:55%;



}
`;



const Right = styled.div`
width: 25%;
height: 60px;
justify-content:center;
display: flex;
align-items: center;
${Media("mobile")} {
  width:25%;
}
`;


const ItemCounter = styled.div`
  position: absolute;
  top: 16px;
  right: 8px;
  background-color:red;
  font-size: 11px;
  height: 16px;
  width: 16px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color:white;
  font-weight:bold;
  ${Media("xlscreens")} {
    top: 0.7vw;
    right: 0.6vw;
    font-size: 0.73vw;
    height: 1.02vw;
    width: 1.02vw;
    border-radius: 0.73vw;
  }
`;


const MenuItem = styled.div`
  font-size: 14px;
  font-family: "gilroysemibold";
  position: relative;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
  cursor: pointer;
  color:#d6a86b;
  &::after {
    position: absolute;
    content: "";
    height: 5px;
    width: 100%;
    background-color: #d6a86b;
    bottom: 1px;
    width: 0px;
    transition: all 0.3s ease;
  }
 
  ${Media("xlscreens")} {
    font-size: 1vw;
    height: 4vw;
    padding: 0 1.46vw;
    &::after {
      height: 0.26vw;
    }
    span{
      margin-left:0.5vw;
      i{
        font-size:0.8vw;
      }
    }
    ${props => {
    if (props.round) {
      return `
          background:rgba(213,167,105,0.1);
          border-radius:50%;
          height:3vw;
          width:3vw;
          padding:0;
          margin-right:0.5vw;
          border:2px solid #d6a86b;
          &::after{
            display:none;
          }
        `;
    }
  }}
  }
`;







export default MobileTopBar
