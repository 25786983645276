import "./navbar.scss";
import { useEffect, useState } from "react";
import { useStateValue } from "../StateProvider";
import { useHistory } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import CompanyLogo from "../../shared-components/company-logo";
import { colors, gilroyBold, gilroyMedium, gilroyRegular, gilroySemibold, Media } from "../../shared-components";
import Productlist from "./productlist"
import Fade from 'react-reveal/Fade'
import { BackServer } from "../../components/Services";
import BedSvg from "../../assets/images/bed.svg";
import TableSvg from "../../assets/images/table.svg";
import SofaSvg from "../../assets/images/sofa.svg";
import ChairSvg from "../../assets/images/chair.svg";
import Wardrobe from "../../assets/images/wardrobe.png";

import CalligraphySvg from "../../assets/images/calligraphy.svg";
import SwingSvg from "../../assets/images/swing.svg";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import LocalMallIcon from '@material-ui/icons/LocalMall';


function Navbar() {
  const history = useHistory();
  const [{ wishlist, Userorders, users }, dispatch] = useStateValue();
  const [productlist, setProductlist] = useState(false);
  const [userProfileMenu, openUserProfileMenu] = useState(false);

  useEffect(() => {
    var wishlistdatafromlocalstorage = JSON.parse(
      localStorage.getItem("wishlist")
    );
    dispatch({
      type: "ADD_TO_WISHLIST",
      payload: wishlistdatafromlocalstorage,
    });

    var ordersdatafromlocalstorage = JSON.parse(localStorage.getItem("Orders"));
    dispatch({
      type: "SHOPNOW",
      payload: ordersdatafromlocalstorage,
    });




    var usersfromlocalstorage = JSON.parse(localStorage.getItem("users"));
    if (usersfromlocalstorage) {
      axios
        .post(
          `${BackServer}/data/getUserOrders/${usersfromlocalstorage._id}`
        )
        .then((res) => {
          dispatch({
            type: "SHOPNOW",
            payload: res.data.order,
          });
        });
    }



  }, []);

  const controlling = (route) => {
    history.push(`${route}`);
  };
  const signoutMethod = () => {
    localStorage.removeItem("users");

    dispatch({
      type: "SET_USER",
      payload: null,
    });

    history.push("/");
  };
  return (
    <div>
       <NavbarWrapper>
        <CompanyLogo />
        <NabarMenus className="navbar__content">
          <MenuItem id="MontserratRegular" onClick={() => controlling("/")}>Home</MenuItem>
          <MenuItem id="MontserratRegular">Products <span><i className="fas fa-chevron-down"></i></span>
            <SubMenu>
              <SubMenuItem >
                <img src={BedSvg} alt="-" />
                Beds
                <i class="fas fa-chevron-right"></i>
                <InnerSubMenu>
                  <InnerSubItem onClick={() => controlling("/woodenbeds")}>
                    <img src={BedSvg} alt="-" />
                   Wooden Carving Beds
                  </InnerSubItem>

                  <InnerSubItem onClick={() => controlling("/modernbeds")}>
                    <img src={BedSvg} alt="-" />
                    Modern Beds
                  </InnerSubItem>

                  {/* <InnerSubItem onClick={() => controlling("/decobeds")}>
                    <img src={BedSvg} alt="-" />
                    Deco Beds Lassani (MDF,ZRK)
                  </InnerSubItem>

                  <InnerSubItem onClick={() => controlling("/polishbed")}>
                    <img src={BedSvg} alt="-" />
                    Polished Beds patex (Wallnet board,lassani,etc)
                  </InnerSubItem> */}
                </InnerSubMenu>
              </SubMenuItem>

              <SubMenuItem>
                <img src={SofaSvg} alt="-" />
                Sofas
                <i class="fas fa-chevron-right"></i>
                <InnerSubMenu>

  
                  <InnerSubItem  onClick={() => controlling("/simplesofas")}>
                    <img src={SofaSvg} alt="-" />
                    Modern Sofas
                  </InnerSubItem>

                  <InnerSubItem  onClick={() => controlling("/woodensofas")}>
                    <img src={SofaSvg} alt="-" />
                    Wooden Sofas
                  </InnerSubItem>
                 
                </InnerSubMenu>
              </SubMenuItem>


              <SubMenuItem onClick={() => controlling("/chairs")}>
                <img src={ChairSvg} alt="-" />
                Chairs
                {/* <i class="fas fa-chevron-right"></i>  */}

                {/* <InnerSubMenu >
                  <InnerSubItem onClick={() => controlling("/chairs")}>
                    <img src={ChairSvg} alt="-" />
                    Chairs
                  </InnerSubItem>
                  <InnerSubItem onClick={() => controlling("/chairs")}>
                    <img src={ChairSvg} alt="-" />
                    Bedroom Chairs
                  </InnerSubItem>
                
                </InnerSubMenu> */}
              </SubMenuItem>

              

              <SubMenuItem>
                <img src={TableSvg} alt="-" />
                Tables
                <i class="fas fa-chevron-right"></i>
                <InnerSubMenu>

                  <InnerSubItem onClick={() => controlling("/dinningstables")}>
                    <img src={TableSvg} alt="-" />
                    Dining Tables
                  </InnerSubItem>

                  <InnerSubItem onClick={() => controlling("/woodentables")}>
                    <img src={TableSvg} alt="-" />
                      Wooden Center Tables
                  </InnerSubItem>

                  <InnerSubItem onClick={() => controlling("/mssstables")}>
                    <img src={TableSvg} alt="-" />
                    SS /
                    MS Center Tables 

                  </InnerSubItem>

              

                  {/* <InnerSubItem onClick={() => controlling("/centerTables")}>
                    <img src={TableSvg} alt="-" />
                      All center tables
                  </InnerSubItem> */}

                </InnerSubMenu>
              </SubMenuItem>


              {/* <SubMenuItem onClick={() => controlling("/calligraphy")}>
                <img src={CalligraphySvg} alt="-" />
                Calligraphy and Sceneries
              </SubMenuItem> */}


         

              <SubMenuItem onClick={() => controlling("/wardrobes")}>
                <img src={Wardrobe} alt="-" />
                Wardrobes
              </SubMenuItem>

              <SubMenuItem onClick={() => controlling("/swings")}>
                <img src={SwingSvg} alt="-" />
                Swings
              </SubMenuItem>


            </SubMenu>
          </MenuItem>
          <MenuItem id="MontserratRegular" onClick={() => controlling("/about")}>About Us</MenuItem>
          <MenuItem id="MontserratRegular" onClick={() => controlling("/contact")}>Contact Us</MenuItem>
          {users !== null && (
            <MenuItem onClick={() => controlling("/User_Orders")}>
              <div id="MontserratRegular" className="orders">
                Your Orders
                {/* <ItemCounter>

                  {Userorders === null ? 0 : Userorders.length}
                </ItemCounter> */}
              </div>
            </MenuItem>
          )}
        </NabarMenus>
        <NavBarRight>

          
          <MenuItem id="MontserratRegular" onClick={() => history.push("/wishlist")} >
            {/* <i style={{fontSize:"20px"}} class="fas fa-heart"></i> */}
            <ShoppingCartIcon/>
            <ItemCounter>{wishlist === null ? 0 : wishlist.length}</ItemCounter>
          </MenuItem>



          {users === null ? (
            <MenuItem id="MontserratRegular" style={{marginTop:"10px"}}
              onClick={() => history.push("/")}
              className="wishlist"
              round
            >
              <i class="fas fa-user"></i>
            </MenuItem>
          ) : (
            <WelcomeUserDiv>
              <UserDiv>
                <span>Welcome</span>
                {users.name}
              </UserDiv>
              <MenuItem id="MontserratRegular" className="wishlist" round onClick={() => openUserProfileMenu(!userProfileMenu)}>
                <i class="fas fa-user"></i>
                <UserProfileDropdown userProfileMenu={userProfileMenu}>
                  <DropdownItem onClick={signoutMethod}>
                    Sign Out
                  </DropdownItem>
                </UserProfileDropdown>
              </MenuItem>
            </WelcomeUserDiv>
          )}
        </NavBarRight>
      </NavbarWrapper>
    </div>


  );
}
const NavbarWrapper = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 0.1px solid white;
  ${Media("xlscreens")} {
    height: 4vw;
  }
`;
const NabarMenus = styled.div`
  display: flex;
`;

const InnerSubItem = styled.div`
  ${Media('xlscreens')}{
    padding:1vw;
    transition:all 0.2s ease;
    border-bottom:1px solid rgba(0,0,0,0.1);
    font-family:${gilroySemibold};
    font-size:0.93vw;
    color:#6a6a6a;
    display:flex;
    img{
      max-height:1.5vw;
      max-width:1.5vw;
      margin-right:1vw;
    }
    &:hover{
      padding-left:1.5vw;
    }
    &:last-of-type{
      border:none;
    }
  }
`;

const InnerSubMenu = styled.div`
  ${Media('xlscreens')}{
    position:absolute;
    right:-20vw;
    width:20vw;
    background-color:white;
    visibility:hidden;
    opacity:0;
    transform:translateY(-20px);
    transition:all 0.2s ease;
    padding:0 2vw;
    border-bottom:6px solid #d6a86b;
    border-left:2px solid #d6a86b;
  }
`;

const SubMenu = styled.div`
  ${Media('xlscreens')}{
    position:absolute;
    width:20vw;
    background-color:white;
    top:4.05vw;
    visibility:hidden;
    opacity:0;
    transform:translateX(-20px);
    transition:all 0.2s ease;
    left:0;
    padding:0 2vw;
    border-bottom:6px solid #d6a86b;
  }
`;

const SubMenuItem = styled.div`
  ${Media('xlscreens')}{
    padding:1vw;
    transition:all 0.2s ease;
    border-bottom:1px solid rgba(0,0,0,0.1);
    font-family:${gilroySemibold};
    font-size:0.93vw;
    color:#6a6a6a;
    display:flex;
    i{
      margin-left:auto;
      font-size:0.65vw;
      position:relative;
      top:0.4vw;
    }
    img{
      max-height:1.5vw;
      max-width:1.5vw;
      margin-right:1vw;
    }
    &:hover{
      padding-left:1.5vw;
        ${InnerSubMenu}{
          visibility:visible;
          opacity:1;
          transform:translateY(0px);
        }
    }
    &:last-of-type{
      border:none;
    }
  }
`;

const UserProfileDropdown = styled.div`
  ${Media('xlscreens')}{
    position:absolute;
    top:3.2vw;
    width:12vw;
    background-color:white;
    box-shadow:0px 5px 10px rgba(0,0,0,0.5);
    right:0;
    visibility:hidden;
    opacity:0;
    transform:translateY(-20px);
    transition:all 0.3s ease;
    ${props => {
    if (props.userProfileMenu) {
      return `
          visibility:visible;
          opacity:1;
          transform:translateY(0px);
        `;
    }
  }}
  }
`;

const DropdownItem = styled.div`
  ${Media('xlscreens')}{
    padding:1vw 2vw;
    &:hover{
      background-color:#d6a86b;
      color:white;
    }
  }
`;

const MenuItem = styled.div`
  font-size: 14px;
  font-family: "gilroysemibold";
  position: relative;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
  cursor: pointer;
  color:#d6a86b;
  &::after {
    position: absolute;
    content: "";
    height: 5px;
    width: 100%;
    background-color: #d6a86b;
    bottom: 1px;
    width: 0px;
    transition: all 0.3s ease;
  }
  &:hover {
    ${SubMenu}{
      visibility:visible;
      opacity:1;
      transform:translateX(0px);
    }
    &::after {
      width: 100%;
    }
  }
  ${Media("xlscreens")} {
    font-size: 1vw;
    height: 4vw;
    padding: 0 1.46vw;
    &::after {
      height: 0.26vw;
    }
    span{
      margin-left:0.5vw;
      i{
        font-size:0.8vw;
      }
    }
    ${props => {
    if (props.round) {
      return `
          background:rgba(213,167,105,0.1);
          border-radius:50%;
          height:3vw;
          width:3vw;
          padding:0;
          margin-right:0.5vw;
          border:2px solid #d6a86b;
          &::after{
            display:none;
          }
        `;
    }
  }}
  }
`;


const NavBarRight = styled.div`
  display: flex;
  position: absolute;
  right: 10px;
  ${Media("xlscreens")} {
    right: 0.73vw;
  }
`;
const ItemCounter = styled.div`
  position: absolute;
  top: 20px;
  right: 6px;
  background-color: ${colors.mainColor};
  color: ${colors.primaryColor};
  font-size: 10px;
  height: 14px;
  width: 14px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${Media("xlscreens")} {
    top: 0.7vw;
    right: 0.6vw;
    font-size: 0.73vw;
    height: 1.02vw;
    width: 1.02vw;
    border-radius: 0.73vw;
  }
`;

const WelcomeUserDiv = styled.div`
  display:flex;
  align-items:center;
`;

const UserDiv = styled.div`
  ${Media('xlscreens')}{
    font-size:1.15vw;
    text-align:right;
    margin-right:15px;
    font-family:${gilroyMedium};
    span{
      display:block;
      font-size:0.9vw;
      font-family:${gilroyRegular};
    }
  }
`;
export default Navbar;