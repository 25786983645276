import React, { useEffect, useState } from 'react'
import "./contact.css"
import { useStateValue } from '../StateProvider'
import { Link, useHistory, useLocation } from 'react-router-dom'
import axios from 'axios'
import CallIcon from '@material-ui/icons/Call';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from 'google-maps-react';
import { auth, database } from '../../database'
import DirectionsIcon from '@material-ui/icons/Directions';
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import contactBg from "../../assets/images/banner1.jpg";
import PhoneIcon from "@material-ui/icons/Phone";
import logo from "../../assets/icons/logo.png"
import { Media } from "./theme/media-queries";



function Contact(props) {
  useEffect(() => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0


  }, [])
  const [email, setemail] = useState('')
  const [phone, setphone] = useState('')
  const [message, setmessage] = useState('')

  const submit = () => {
    var data = {
      email: email,
      phone: phone,
      message: message
    }
    if (data.email === "") {
      alert("Enter Email")
    }
    else if (data.phone === "") {
      alert("Enter Phone Number")

    }

    else if (data.message === "") {
      alert("Enter Message")

    }
    else {
      database
        .ref(`contact`)
        .push(data)
        .then(response => {


          setemail('')
          setphone('')
          setmessage('')

          alert("Thanku for your contact we will respond as soon as possible")


        })


        .catch(error => {
          alert(error)
        });
    }


  }
  return (
    // <div style={{backgroundColor:"#9f7757"}} class="container-contact100">


    //     <div class="contact100-more">

    //         <CallIcon style={{ marginRight: "5%" }} />
    //   	0331-2380673
    // </div>

    //     <div class="contact100-more2" onClick={() => window.open('https://goo.gl/maps/kWMdRfwtdG7wwaqAA', '_blank')} >
    //         <DirectionsIcon style={{ marginRight: "5%" }} />
    // 	Direction

    //      </div>

    //     <div class="wrap-contact100">
    //         <div class="contact100-form validate-form">


    //             <span id="MontserratSemiBold" style={{color:"#9f7757"}} class="contact100-form-title">
    //                 Contact Us
    // 		    </span>



    //             <div class="wrap-input100 validate-input" data-validate="Valid email is required: ex@abc.xyz">
    //                 <span class="label-input100">Email</span>
    //                 <input value={email}  onChange={(e)=>setemail(e.target.value)} class="input100 inputClass" type="text" name="email" placeholder="Email addess..." />
    //                 <span class="focus-input100"></span>
    //             </div>

    //             <div class="wrap-input100">
    //                 <span class="label-input100">Phone</span>
    //                 <input value={phone}  onChange={(e)=>setphone(e.target.value)} class="input100 inputClass" type="text" name="phone" placeholder="Phone Number..." />
    //                 <span class="focus-input100"></span>
    //             </div>

    //             <div class="wrap-input100 validate-input" data-validate="Message is required">
    //                 <span class="label-input100">Message</span>
    //                 <textarea value={message}   onChange={(e)=>setmessage(e.target.value)} class="input100" name="message" placeholder="Questions/Comments..."></textarea>
    //                 <span class="focus-input100"></span>
    //             </div>

    //             <div class="container-contact100-form-btn">
    //                 <div class="wrap-contact100-form-btn">
    //                     <div class="contact100-form-bgbtn"></div>
    //                     <button onClick={submit} class="contact100-form-btn">
    //                         Send
    // 				</button>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // </div>

    <Wrapper>
      <ContactIntro>
        <h1 style={{ marginTop: "100px" }}>Get in Touch</h1>
        <p style={{ color: "white", fontFamily: "verdana" }}>
          Want to get in touch? We'd love to hear from you. Here's how you can
          reach us.
        </p>
      </ContactIntro>
      <GetinTouch>
        <Container>
          <Row>
            <Col lg={6} md={6}>
              <ContactBox>
                <PhoneIcon />
                <h2>Talk to us.</h2>
                <p>
                  Want Contact? Just pick-up your phone and call at our
                  given numbers.
                </p>
                <h3>+92 331 2380673</h3>
                <h3>+92 300 3398810</h3>
              </ContactBox>
            </Col>
            <Col lg={6} md={6}>
              <ContactBox>
                <DirectionsIcon />
                <h2>Reach us.</h2>
                <p>
                  ICON WOOD LOCATED AT TAUHEED COMMERCIAL PHASE 5 DHA,KARACHI,SHOP NO#2,BUILDING#7-C,STREET#32.
                  <br />
                  11:00:Am - 09:00:Pm (Sunday closed).
                </p>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://www.google.com/maps/dir/24.808281,67.038243/iconwood/@24.808394,67.0362032,17z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3eb33dba62953fc5:0xaf30bc3d1c024070!2m2!1d67.0383428!2d24.8083034?entry=ttu"
                >
                  Get Direction
                </a>
              </ContactBox>
            </Col>
          </Row>
        </Container>
      </GetinTouch>
    </Wrapper>
  )
}


const Wrapper = styled.div`
  min-height: 100vh;
  margin-top: 6px;
  ${Media("xlscreens")} {
    margin-top: 0vw;
  }
  ${Media('tablet')}{
    margin-top:10px;
  }
`;
const ContactIntro = styled.div`
  min-height: 70vh;
  background-image: url(${contactBg});
  background-size: cover;
  position: relative;
  z-index: 9;
  background-repeat: no-repeat;
  color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
  h1 {
    font-size: 52px;
  }
  p {
    font-size: 18px;
  }
  &:before {
    background: #00000070;
    content: "";
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: -9;
  }
  ${Media("xlscreens")} {
    h1 {
      font-size: 2.7vw;
    }
    p {
      font-size: 1vw;
    }
  }
  ${Media("tablet")} {
    text-align: center;
    padding: 0px 15px;
    min-height: 60vh;
    h1{
      font-size:40px;
    }
    p{
      font-size:18px;
    }
  }
`;
const GetinTouch = styled.div`
  margin-top: -100px;
  position: relative;
  z-index: 99;
  ${Media("xlscreens")} {
    margin-top: -15.2vw;
  }
`;
const ContactBox = styled.div`
  background-color: white;
  min-height: 300px;
  box-shadow: 0px 0px 10px #00000050;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 80px;
  text-align: center;
  h2 {
    font-size: 32px;
    margin: 10px 0;
  }
  p {
    font-size: 15px;
    margin: 15px 0;
    margin-bottom: 20px;

  }
  h3 {
    color: #2b93c5;
    font-size: 20px;
  }
  svg {
    font-size: 60px;
  }
  a {
    font-size: 20px;
    color: #2b93c5;
  }
  ${Media("xlscreens")} {
    min-height: 20.83vw;
    padding: 0 4.16vw;
    h2 {
      font-size: 1.9vw;
      margin: 0.53vw 0;
    }
    p {
      font-size: 0.9vw;
      margin: 0.78vw 0;
      margin-bottom: 1.04vw;
      font-family:verdana;
    }
    h3 {
      font-size: 1.04vw;
    }
    svg {
      font-size: 3.125vw;
    }
    a {
      font-size: 1.04vw;
    }
  }
  ${Media('tablet')}{
    padding: 0px 20px;
    margin-bottom:20px;
    h2 {
      font-size: 30px;
      margin: 10px 0;
    }
    p {
      font-size: 10px;
      margin: 15px 0;
      margin-bottom: 20px;
    }
    h3 {
      font-size: 18px;
    }
    svg {
      font-size: 36px;
    }
    a {
      font-size: 18px;
    }
  }
`;


export default Contact;