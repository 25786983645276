import React, { useState, useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import section3 from '../../../assets/images/1.jpg'
import section3two from '../../../assets/images/2.jpg'
import ChairItem from '../../../assets/images/item.png'
import Center1 from '../../../assets/images/center1.jpg'
import Center2 from '../../../assets/images/center2.jpg'
import Center3 from '../../../assets/images/center3.jpg'
import Center4 from '../../../assets/images/center4.jpg'
import sofa131 from '../../../assets/images/sofa131.png'
import dining131 from '../../../assets/images/diningdesktop.jpg'

import wwww from '../../../assets/images/www.png'
import bedroomchair from '../../../assets/images/bedchair.jpg'

import { MdOutlineChair } from 'react-icons/md';
import { BsBookshelf } from 'react-icons/bs';
import { IoIosBed } from 'react-icons/io';
import { AiFillLike } from 'react-icons/ai';
import Zoom from 'react-reveal/Zoom';

import { FiUsers } from 'react-icons/fi';

import { Carousel } from "react-bootstrap";


import Wardrobe from "../../../assets/images/wardrobe.png";
import Centertable from "../../../assets/images/centertable.png";
import Woodensofa from "../../../assets/images/woodensofa.png";


import TableSvg from "../../../assets/images/table.svg";
import SofaSvg from "../../../assets/images/sofa.svg";
import ChairSvg from "../../../assets/images/chair.svg";
import CalligraphySvg from "../../../assets/images/calligraphy.svg";
import SwingSvg from "../../../assets/images/swing.svg";






import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'


import Fade from 'react-reveal/Fade'
import { Server } from "../../Services"

import { useStateValue } from '../../StateProvider'
import { auth, storage, db, database } from '../../../database'

import './section2.css'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    marginTop: '2%',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}))

function LandingSection5() {
  const classes = useStyles()
  const [{ section2Items, section3Items, wishlist, users }, dispatch] = useStateValue()
  const [color, setcolor] = useState(1)

  useEffect(() => {
    var values = []
    database.ref(`Section2Data`).once('value', (snap) => {
      var fetchData = snap.val()
      for (let keys in fetchData) {
        values.push({ ...fetchData[keys], key: keys })
      }
      dispatch({
        type: 'SECTION2_DATA',
        payload: values,
      })


    })


    var values2 = []
    database.ref(`Section3Data`).once('value', (snap) => {
      var fetchData = snap.val()
      for (let keys in fetchData) {
        values2.push({ ...fetchData[keys], key: keys })
      }
      values2.sort((a, b) => a - b).reverse()

      dispatch({
        type: 'SECTION3_DATA',
        payload: values2,
      })


    })

  }, [section2Items])

  const Selectcolor = (val) => {
    setcolor(val)
  }

  const _handleWishlistTrue = (item) => {
    var wishlistData = {
      title: item.title,
      url: item.url,
      url2: item.url2,
      url3: item.url3,
      wishlist: true,
      key: item.key,
      trackingid: item.trackingid,

      oldrate: item.oldrate,
      newrate: item.newrate,
      route: item.route,
      imagelink: item.imagelink,
      imagelink2: item.imagelink2,
      imagelink3: item.imagelink3,
      imagelink4: item.imagelink4,
      imagelink5: item.imagelink5,

      percent: item.percent,
      dimensions: item.dimensions,
      descsectiontwo: item.descsectiontwo,

    }

    var wishlistArray = JSON.parse(localStorage.getItem('wishlist'))
    var newArray = []
    if (wishlistArray === null) {
      newArray.push(wishlistData)
    } else {
      newArray = wishlistArray
      newArray.push(wishlistData)
    }
    localStorage.setItem('wishlist', JSON.stringify(newArray))

    dispatch({
      type: 'ADD_TO_WISHLIST',
      payload: newArray,
    })
  }

  const _handleWishlistFalse = (item) => {
    const wishlistData = {
      title: item.title,
      url: item.url,
      wishlist: false,
      trackingid: item.trackingid,

      oldrate: item.oldrate,
      newrate: item.newrate,
      route: item.route,
      imagelink: item.imagelink,
      imagelink2: item.imagelink2,
      imagelink3: item.imagelink3,
      imagelink4: item.imagelink4,
      imagelink5: item.imagelink5,
      percent: item.percent,
      dimensions: item.dimensions,
      descsectiontwo: item.descsectiontwo,

    }

    var wishlistItems = JSON.parse(localStorage.getItem('wishlist'))
    var newRemoveArray

    if (wishlistItems) {
      newRemoveArray = wishlistItems.filter((f, i) => f.key !== item.key)
    } else {
      newRemoveArray = wishlistItems
    }

    localStorage.setItem('wishlist', JSON.stringify(newRemoveArray))

    dispatch({
      type: 'REMOVE_FROM_BASKET',
      payload: newRemoveArray,
    })
  }

  var wishlistTrueArray = JSON.parse(localStorage.getItem('wishlist'))
  const checkIfAdded = (key) => {
    const checked =
      wishlistTrueArray && wishlistTrueArray.filter((f) => f.key === key)
    if (checked && checked.length > 0) {
      return true
    } else {
      return false
    }
  }

  var p = window.location.search;
  var productid = p.split("=");
  var productId = productid[1]


  return (
    <div id="section5" style={{ width: "100%", marginTop: "0px", marginBottom: "80px" }} className={classes.root}>





      <Grid className='desktopshopcategory' style={{ marginTop: "50px" }} container spacing={3}>

        <Grid style={{ display: "flex", flexDirection: "column", alignItems: "center" }} item xs={12}>
          <h1 className="MontserratRegular headForMobile" style={{ fontWeight: "bold" }}>SHOP BY CATEGORY</h1>
          <div style={{ borderBottom: "4px solid green", width: "140px", marginTop: "-20px", color: "#eeede9" }}>.</div>
        </Grid>

      </Grid>


      <Grid className='desktopshopcategory' style={{ marginTop: "55px", borderTop: "2px solid #8080806e", borderBottom: "2px solid #8080806e", width: "95%", marginLeft: "3%", height: "100px", alignItems: "center" }} container spacing={3}>
        <Grid onClick={() => window.open(`${Server}/wardrobes`, '_blank')} style={{ display: "flex", justifyContent: "center", fontSize: "38px", borderRight: "2px solid #8080806e", height: "100%", alignItems: "center", flexDirection: "column" }} item xs={4}>
          <img width="45%" src={Wardrobe} alt="-" />

          <div style={{ marginTop: "12px" }}>
            <h1 style={{ fontSize: "10px", color: "#808080f2", fontWeight: "bold" }}>Wardrobes</h1>
          </div>
        </Grid>
        <Grid onClick={() => window.open(`${Server}/centerTables`, '_blank')} style={{ display: "flex", justifyContent: "center", fontSize: "38px", borderRight: "2px solid #8080806e", height: "100%", alignItems: "center", flexDirection: "column" }} item xs={4}>
          <img width="45%" src={Centertable} alt="-" />

          <div style={{ marginTop: "12px" }}>
            <h1 style={{ fontSize: "10px", color: "#808080f2", fontWeight: "bold" }}>Center Tables</h1>
          </div>
        </Grid>
        <Grid onClick={() => window.open(`${Server}/woodensofas`, '_blank')} style={{ display: "flex", justifyContent: "center", fontSize: "38px", height: "100%", alignItems: "center", flexDirection: "column" }} item xs={4}>
          <img width="38%" src={Woodensofa} alt="-" />

          <div style={{ marginTop: "16px" }}>
            <h1 style={{ fontSize: "10px", color: "#808080f2", fontWeight: "bold" }}>Wooden Sofas</h1>
          </div>
        </Grid>
      </Grid>


      <Grid className='desktopshopcategory' style={{ marginTop: "10px", borderBottom: "2px solid #8080806e", width: "95%", marginLeft: "3%", height: "110px", alignItems: "center" }} container spacing={3}>
        <Grid onClick={() => window.open(`${Server}/woodenbeds`, '_blank')} style={{ display: "flex", justifyContent: "center", fontSize: "38px", borderRight: "2px solid #8080806e", height: "100%", alignItems: "center", flexDirection: "column" }} item xs={4}>
          <IoIosBed />
          <div style={{ marginTop: "12px" }}>
            <h1 style={{ fontSize: "10px", color: "#808080f2", fontWeight: "bold" }}>Beds</h1>
          </div>
        </Grid>
        <Grid onClick={() => window.open(`${Server}/dinningstables`, '_blank')} style={{ display: "flex", justifyContent: "center", fontSize: "38px", borderRight: "2px solid #8080806e", height: "100%", alignItems: "center", flexDirection: "column" }} item xs={4}>
          <img width="38%" src={TableSvg} alt="-" />
          <div style={{ marginTop: "25px" }}>
            <h1 style={{ fontSize: "10px", color: "#808080f2", fontWeight: "bold" }}>Dining Tables</h1>
          </div>
        </Grid>
        <Grid onClick={() => window.open(`${Server}/simplesofas`, '_blank')} style={{ display: "flex", justifyContent: "center", fontSize: "38px", flexDirection: "column", alignItems: "center" }} item xs={4}>
          <img width="45%" src={SofaSvg} alt="-" />
          <div style={{ marginTop: "20px" }}>
            <h1 style={{ fontSize: "10px", color: "#808080f2", fontWeight: "bold" }}>Modern Sofas</h1>
          </div>
        </Grid>
      </Grid>


      <Grid className='desktopshopcategory' style={{ marginTop: "10px", borderBottom: "2px solid #8080806e", width: "95%", marginLeft: "3%", height: "110px", alignItems: "center" }} container spacing={3}>
        <Grid onClick={() => window.open(`${Server}/chairs`, '_blank')} style={{ display: "flex", justifyContent: "center", fontSize: "38px", borderRight: "2px solid #8080806e", height: "100%", alignItems: "center", flexDirection: "column" }} item xs={4}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
            <img width="38%" src={ChairSvg} alt="-" />
            <h1 style={{ fontSize: "10px", color: "#808080f2", fontWeight: "bold", paddingTop: "10px" }}>Chairs</h1>

          </div>

        </Grid>

        <Grid onClick={() => window.open(`${Server}/calligraphy`, '_blank')} style={{ display: "flex", justifyContent: "center", fontSize: "38px", borderRight: "2px solid #8080806e", height: "100%", alignItems: "center", flexDirection: "column" }} item xs={4}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
            <img width="38%" src={CalligraphySvg} alt="-" />
            <h1 style={{ fontSize: "10px", color: "#808080f2", fontWeight: "bold", paddingTop: "20px" }}>Calligraphy</h1>

          </div>

        </Grid>
        <Grid onClick={() => window.open(`${Server}/swings`, '_blank')} style={{ display: "flex", justifyContent: "center", fontSize: "38px", flexDirection: "column", alignItems: "center" }} item xs={4}>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
            <img width="38%" src={SwingSvg} alt="-" />
            <h1 style={{ fontSize: "11px", color: "#808080f2", fontWeight: "bold", paddingTop: "25px" }}>Swings</h1>

          </div>

        </Grid>
      </Grid>



      <Grid style={{ marginTop: "70px" }} container spacing={3}>

        <Grid style={{ display: "flex", flexDirection: "column", alignItems: "center" }} item xs={12}>
          <h1 className="MontserratRegular headForMobile" style={{ fontWeight: "bold" }}>NEW ARRIVALS</h1>
          <div style={{ borderBottom: "4px solid green", width: "120px", marginTop: "-20px", color: "#eeede9" }}>.</div>
        </Grid>

      </Grid>

      <Grid className="newArrivals" style={{ marginTop: "35px", }} container spacing={3}>

        <Grid item xs={1}>
        </Grid>
        <Grid style={{ height: "350px" }} item xs={12} sm={5}>


          <Carousel pause={false} interval={3000} indicators={false} controls={false} >
            {section2Items && section2Items.map((item45) => {
              return (
                <Carousel.Item >
                  <div >
                    <div style={{ color: "red", position: "absolute", backgroundColor: "red", border: "3px solid grey", left: "10px", color: "white", borderRadius: "40px", width: "50px", height: "50px", display: "flex", justifyContent: "center", alignItems: "center", fontWeight: "bold", flexDirection: "column" }}>
                      <p style={{ color: "white", fontWeight: "bold", fontSize: "10px" }}>
                        {item45.percent}%
                      </p>
                      <p style={{ color: "white", fontWeight: "bold", fontSize: "10px" }}>OFF</p>

                    </div>
                    <div onClick={() => window.open(`${Server}/ViewProduct?productId=${item45.key}`, '_blank')} style={{ display: "flex", justifyContent: "center" }} >
                      <img
                        width="70%"
                        src={item45.url}
                        alt="home__banner"
                      />
                    </div>

                    <div style={{ width: "100%" }}>
                      <div style={{ width: "75%", padding: "20px" }}>
                        {item45.title}
                      </div>

                      <div style={{ width: "75%", display: "flex", paddingLeft: "20px", marginTop: "-14px" }}>
                        <div style={{ fontSize: "17px", fontWeight: "bold", color: "black" }}>
                          PKR {item45.newrate}
                        </div>
                        <div style={{ paddingLeft: "10px", fontSize: "16px", color: "grey" }}>
                          PKR {item45.oldrate}
                          <div style={{ borderBottom: "2px solid red", color: "white", marginTop: "-37px" }}>
                            .
                          </div>
                        </div>
                      </div>
                      <div style={{ width: "100%", display: "flex" }}>
                        <div style={{ width: "65%", display: "flex", justifyContent: "center", alignItems: "center" }}>

                          <button
                            style={{
                              padding: "10px", fontWeight: "bold", marginLeft: "20px", marginTop: "20px", paddingLeft: "30px", paddingRight: "30px",
                              backgroundColor: '#21b259',
                              color: 'white',
                            }}


                          >
                            <span>
                              {(checkIfAdded(item45.key) && (
                                <ShoppingCartIcon
                                  onClick={() => _handleWishlistFalse(item45)}
                                  style={{ color: 'red', fontSize: "14px" }}
                                />
                              )) || (
                                  <ShoppingCartIcon
                                    onClick={() => _handleWishlistTrue(item45)}
                                    style={{ fontSize: "14px" }}
                                  />
                                )}
                            </span>{' '}
                            {(checkIfAdded(item45.key) && (
                              <span style={{ fontWeight: "bold", fontSize: "10px" }} onClick={() => _handleWishlistFalse(item45)}>
                                Remove From Cart
                              </span>
                            )) || (
                                <span style={{ fontSize: "10px", fontWeight: "bold" }} onClick={() => _handleWishlistTrue(item45)}>
                                  Add To Cart
                                </span>
                              )}
                          </button>
                        </div>

                        <div style={{ width: "35%", display: "flex", justifyContent: "center" }}>
                          <button onClick={() => window.open(item45.route, '_blank')} style={{ backgroundColor: "#d5a769", padding: "10px", fontWeight: "bold", color: "white", marginTop: "20px", borderRadius: "5px", paddingLeft: "20px", paddingRight: "20px", fontSize: "10px" }}>Explore More</button>
                        </div>
                      </div>


                    </div>
                  </div>

                </Carousel.Item>

              )
            })}
          </Carousel>
        </Grid>
        <Grid className='satisfactionDesktop' style={{ display: "none" }} xs={7} sm={5}>
          <Zoom >

            <Carousel pause={false} interval={10000} indicators={false} controls={false} >

              <Carousel.Item >
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#f8f8f8", paddingTop: "50px", paddingBottom: "100px" }}>
                  <div style={{ backgroundColor: "black", height: "50px", width: "50px", borderRadius: "40px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <AiFillLike style={{ color: "white" }} />
                  </div>
                  <div style={{ marginLeft: "30px" }}>
                    <div style={{ fontSize: "17px", fontWeight: "bold" }}>
                      satisfaction guaranteed
                    </div>
                    <div style={{ marginTop: "10px", fontSize: "13px" }}>
                      We stand behind the products we ship.
                    </div>
                  </div>
                </div>
              </Carousel.Item>

              <Carousel.Item >
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#f8f8f8", paddingTop: "50px", paddingBottom: "100px" }}>
                  <div style={{ backgroundColor: "black", height: "50px", width: "50px", borderRadius: "40px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <FiUsers style={{ color: "white" }} />
                  </div>
                  <div style={{ marginLeft: "30px" }}>
                    <div style={{ fontSize: "17px", fontWeight: "bold" }}>
                      loyalty program
                    </div>
                    <div style={{ marginTop: "10px", fontSize: "13px" }}>
                      save with our friends and family program.
                    </div>
                  </div>
                </div>
              </Carousel.Item>
            </Carousel>
          </Zoom>
        </Grid>
        <Grid item xs={1}>
        </Grid>
      </Grid>







      <Grid className='margintopdesktop131'  container spacing={3} style={{ marginTop: "20px" }}>
        <Grid item xs={1}>
        </Grid>
        <Grid onClick={() => window.open(`${Server}/${'dinningstables'}`, '_blank')} style={{ display: "flex", justifyContent: "center" }} item xs={12} sm={6}>
          <Fade right >
            <img className='desktopimagedining131' style={{ cursor: "pointer" }} src={dining131} width="95%" />
          </Fade>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Zoom >
            <div className="main5fordetail" style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "12%" }}>
              <h2 className="MontserratRegular headForMobile" style={{ fontWeight: "bold" }}>A table to gather around</h2>
              <div className="MontserratSemiBold paraForMobile" style={{ textAlign: "justify", width: "80%", paddingTop: "20px", lineHeight: "28px" }}>
                Look ahead to happier days and ready your dining space for when you can gather family and friends close again. From the solid oak table at its heart to the chairs, benches, dressers and sideboards that are its perfect partners, you’ll find designs to suit your style of get-together in our collection.
              </div>

              <div className="exploreDinning" onClick={() => window.open(`${Server}/${'dinningstables'}`, '_blank')} style={{ cursor: "pointer", width: "70%", height: "5vh", background: '#d5a769', display: "flex", justifyContent: "center", borderRadius: "5px", marginTop: "10%", color: "white", alignItems: "center" }}>
                Explore Dining
              </div>
            </div>

          </Zoom>
        </Grid>
        <Grid item xs={1}>
        </Grid>
      </Grid>


      <Grid className='margintopdesktop132' container spacing={3}>

        <Grid item xs={1}>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Zoom >
            <div className="headText ">
              <h1 className="MontserratRegular headForMobile" style={{ fontWeight: "bold" }}>WHY <span>ICON WOOD?</span></h1>
              <div style={{ borderBottom: "4px solid green", width: "140px", marginTop: "-20px", color: "#eeede9" }}>.</div>
              <div className="MontserratSemiBold paraForMobile" style={{ textAlign: "justify", width: "80%", paddingTop: "20px", lineHeight: "28px" }}>
                At ICON WOOD, we do what we do from our Home Design Service to the pieces we make because we believe that good, considered design can make your life that little bit happier and easier. We do that using the best materials and the most trustworthy of techniques, surrounded by people who genuinely care about creating things that are timeless. We’re also always asking ‘could we do this better?’ And that’s where our ‘different perspective’ comes in.
              </div>
            </div>

          </Zoom>

        </Grid>

        <Grid style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }} item xs={12} sm={5}>
          <Fade right >
            <img className="imageOpacity" src={bedroomchair} width="80%" />
          </Fade>

          <div onClick={() => window.open(`${Server}/${"about"}`, '_blank')} className="readMoreButton" style={{ width: "50%", height: "5vh", background: '#d5a769', display: "flex", justifyContent: "center", borderRadius: "5px", marginTop: "12%", color: "white", alignItems: "center", cursor: "pointer" }}>
            Read More About ICON WOOD
          </div>
        </Grid>
        <Grid item xs={1}>
        </Grid>
      </Grid>




      <Grid container spacing={3}>

        <Grid item xs={1}>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Zoom >

            <Carousel pause={false} interval={5000} indicators={false} controls={false} >

              <Carousel.Item >
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#f8f8f8", paddingTop: "50px", paddingBottom: "100px" }}>
                  <div style={{ backgroundColor: "black", height: "50px", width: "50px", borderRadius: "40px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <AiFillLike style={{ color: "white" }} />
                  </div>
                  <div style={{ marginLeft: "30px" }}>
                    <div style={{ fontSize: "17px", fontWeight: "bold" }}>
                      satisfaction guaranteed
                    </div>
                    <div style={{ marginTop: "10px", fontSize: "13px" }}>
                      We stand behind the products we ship.
                    </div>
                  </div>
                </div>
              </Carousel.Item>

              <Carousel.Item >
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#f8f8f8", paddingTop: "50px", paddingBottom: "100px" }}>
                  <div style={{ backgroundColor: "black", height: "50px", width: "50px", borderRadius: "40px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <FiUsers style={{ color: "white" }} />
                  </div>
                  <div style={{ marginLeft: "30px" }}>
                    <div style={{ fontSize: "17px", fontWeight: "bold" }}>
                      loyalty program
                    </div>
                    <div style={{ marginTop: "10px", fontSize: "13px" }}>
                      save with our friends and family program.
                    </div>
                  </div>
                </div>
              </Carousel.Item>
            </Carousel>
          </Zoom>
        </Grid>
        <Grid item xs={1}>
        </Grid>
      </Grid>

    </div>
  )
}

export default LandingSection5



