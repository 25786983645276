import React, { useState, useEffect } from 'react'
import './userlogin.css'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { auth, db, database } from '../../../database'
import { useStateValue } from '../../StateProvider'
import createBrowserHistory from "history/createBrowserHistory";
import axios from 'axios'
import logo from "../../../assets/icons/logo.png"
import ClipLoader from "react-spinners/ClipLoader";
import PropagateLoader from "react-spinners/PropagateLoader";
import { BackServer } from "../../Services"
import logo3 from "../../../assets/icons/logo3.png"

import Banner from '../../../assets/images/www.png'

function Login() {
  const history = useHistory()
  const location = useLocation();
  const customHistory = createBrowserHistory();
  const [email, setEmail] = useState('')
  const [name, setname] = useState('')
  const [password, setPassword] = useState('')
  const [address, setaddress] = useState('')
  const [city, setcity] = useState('')

  const [contact, setcontact] = useState('')
  const [inputSelector, setinputSelector] = useState('Register')
  const [{ users }, dispatch] = useStateValue()
  const [processing, setProcessing] = useState(false)
  const [disabled, setDisabled] = useState(true)

  const [processing2, setProcessing2] = useState(false)
  const [processing3, setProcessing3] = useState(false)

  const [disabled2, setDisabled2] = useState(true)
  const [order, setorder] = useState('')

  const [placeorder, setplaceorder] = useState(false)

  var previousLocation = location.state === 'previousLocation' && location.state.previousLocation
  // var items = location.state.item



  useEffect(() => {

    setorder(history.location.state)

    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0

  }, [])


  const signIn = (e) => {
    setinputSelector('signIn')

    if (inputSelector === 'signIn') {
      e.preventDefault()

      if (contact === "") {
        alert("Enter Contact")
      }
      else if (password === "") {
        alert("Enter Password")
      }

      else {

        setProcessing(true)

        var loginData = {
          email: email,
          password: password,
          contact: contact
        }

        axios
          // .post(`https://iconwood.herokuapp.com/data/login`, loginData)
          .post(`${BackServer}/data/login`, loginData)


          .then((res) => {

            if (res.data.success !== true) {
              alert(JSON.stringify(res.data.error))
              setProcessing(false)

            }


            else {
              localStorage.setItem('users', JSON.stringify(res.data.user))
              var users = JSON.parse(localStorage.getItem('users'))
              // var users = res.data.user
              if (users) {
                dispatch({
                  type: 'SET_USER',
                  payload: users,
                })
              } else {
                dispatch({
                  type: 'SET_USER',
                  payload: null,
                })
              }
              history.push('/')
              setProcessing(false)

            }
          })

      }

    }
  }




  const register = (e) => {
    setinputSelector('Register')

    if (inputSelector === 'Register') {
      e.preventDefault()


      if (name === "" ||  password === "" || contact === "" || address === "" || city === "") {
        alert("All fields are required")
      }
      else if (name.search(/[a-zA-Z]/) == -1) {
        alert("Name should be Alphabetic")
      }
   
      else if (contact.length !== 11) {
        alert("Invalid Contact Entered")
      }
      else if (password.length < 8) {
        alert("Password should be atleast 8 characters long")
      }




      else {


        setProcessing2(true)

        var userData = {
          name: name,
          // email: email,
          password: password,
          contact: contact,
          city: city,
          address: address
        }
        axios
          // .post(`https://iconwood.herokuapp.com/data/signup`, userData)
          .post(`${BackServer}/data/signup`, userData)

          .then((res) => {
            if (res.data.success !== true) {
              alert(JSON.stringify(res.data.error))
              setProcessing2(false)
            }


            else {


              localStorage.setItem('users', JSON.stringify(res.data.user))
              var users = JSON.parse(localStorage.getItem('users'))
              if (users) {
                dispatch({
                  type: 'SET_USER',
                  payload: users,
                })
              } else {
                dispatch({
                  type: 'SET_USER',
                  payload: null,
                })
              }
              history.push('/')
              setProcessing2(false)


            }


          })


      }
    }

  }
  const PlaceorderOpen = () => {
    if (name === "" || password === "" || contact === "" || address === "" || city === "") {
      alert("All fields are required")
    }
    else if (name.search(/[a-zA-Z]/) == -1) {
      alert("Name should be Alphabetic")
    }
  
    else if (contact.length !== 11) {
      alert("Invalid Contact Entered")
    }
    else if (password.length < 8) {
      alert("Password should be atleast 8 characters long")
    }
    else {
      setplaceorder(true)

    }
  }

  const Placeorder = (e) => {

    setinputSelector('Register')


    if (inputSelector === 'Register') {
      e.preventDefault()



      setProcessing3(true)

      var userData = {
        name: name,
        // email: email,
        password: password,
        contact: contact,
        city: city,
        address: address
      }
      axios
        // .post(`https://iconwood.herokuapp.com/data/signup`, userData)
        .post(`${BackServer}/data/signup`, userData)

        .then((res) => {
          if (res.data.success !== true) {
            alert(JSON.stringify(res.data.error))
            setProcessing3(false)
            setplaceorder(false)



          }


          else {


            localStorage.setItem('users', JSON.stringify(res.data.user))
            var users = JSON.parse(localStorage.getItem('users'))
            if (users) {
              dispatch({
                type: 'SET_USER',
                payload: users,
              })
            } else {
              dispatch({
                type: 'SET_USER',
                payload: null,
              })
            }
            history.push('/')
            setProcessing3(false)

            // direct order 
            if (order) {
              var orderData = {
                title: order && order.title,
                descsectiontwo: order && order.descsectiontwo,
                newrate: order && order.newrate,
                oldrate: order && order.oldrate,

                url: order && order.url,
                wishlist: order && order.wishlist,
                key: order && order.key,
                userId: users._id,
                username: order && name,
                useremail: order && email,
                usercontact: order && contact,
                trackingid: order && Math.floor(Math.random() * 100000000000000),
                status: order && "Pending",
                orderDate: order && new Date().toLocaleDateString('de-DE'),
                key: order && order.key,


                route: order && order.route,
                imagelink: order && order.imagelink,
                imagelink2: order && order.imagelink2,
                imagelink3: order && order.imagelink3,
                imagelink4: order && order.imagelink4,
                imagelink5: order && order.imagelink5,
                percent: order && order.percent,
                dimensions: order && order.dimensions,

              }

              axios
                .post(`${BackServer}/data/UserOrders`, orderData)
                .then((res) => {
                  setProcessing(false)

                  alert('Order Succussfully Placed We will Contact You Soon.')
                  window.location.reload()


                  axios
                    .post(`${BackServer}/data/UserOrdersAdmin`, orderData)
                    .then((res) => {
                      window.location.reload()

                    })




                })

            }
            // direct order end 


          }


        })


    }
  }

  return (
    <div className="User__login">



      {order && placeorder === true &&
        <div className='orderMobile' style={{backgroundColor: "white", width: "100%", position: "absolute", display: "flex", alignItems: "center", flexDirection: "column", }}>
          <div style={{ backgroundColor: "white", width: "90%", position: "absolute", display: "flex",  alignItems: "center", flexDirection: "column" }}>

            <div className='mobileWidth' style={{ backgroundColor: "#000000b3", color: "#ffffff", width: "50%",margin:"20px", display: "flex", justifyContent: "space-around", height: "80px", alignItems: "center" }}>
              <div style={{ fontWeight: "bold" }}>product</div>
              <div style={{ fontWeight: "bold" }}>Total</div>

            </div>

            <div className='mobileWidth' style={{ backgroundColor: "#eaedf0", width: "50%", display: "flex", justifyContent: "space-around", height: "120px", alignItems: "center" }}>
            <div><img src={order.url} width="120px" /></div>
              <div>{order.newrate}</div>
            </div>


            <div className='mobileWidth' style={{ backgroundColor: "#eaedf0", width: "50%", display: "flex",borderTop:"2px solid white", justifyContent: "space-around", height: "120px", alignItems: "center", paddingBottom: "15px" }}>
            <div>{address}</div>
            </div>

         

            <div className='mobileWidth' style={{ backgroundColor: "#0000007d", border: "6px solid white", width: "50%", display: "flex", justifyContent: "space-around", height: "150px", alignItems: "center" }}>
              <div style={{ backgroundColor: "#eaedf0", width: "95%", height: "130px", display: "flex", justifyContent: "center", alignItems: "center",padding:"10px" ,fontWeight:"bold",fontSize:"14px"}} >
                By clicking on the Place Order button,
                We will call you for further details about the product like
                : <br /> (Sizes, Quantity, Wood, Texture etc.)

              </div>
            </div>

            <div className='mobileWidth' style={{ backgroundColor: "#0000007d", border: "2px solid white", width: "50%", display: "flex", justifyContent: "space-around", height: "50px", alignItems: "center", paddingBottom: "10px" }}>
              <button
                onClick={Placeorder}
                className={
                  inputSelector === 'Register' && 'User__login__signInButton'
                }
                style={{ width: "100%", fontWeight: "bold" }}
              >

                {!processing3 ?

                  <span >
                    Place Order
                  </span>
                  :
                  <span>
                    <PropagateLoader color="white" width={100}

                    />
                  </span>
                }
              </button>

              <button
                onClick={() => setplaceorder(false)}
                className={
                  inputSelector === 'Register' && 'User__login__signInButton'
                }
                style={{ width: "80%", fontWeight: "bold", backgroundColor: "green", marginLeft: "5px" }}
              >



                <span >
                  back
                </span>

              </button>
            </div>

          </div>
        </div>
      }



      {inputSelector === 'signIn' && placeorder=== false && (
        <div className="User__login__container">


          <h4 style={{ fontWeight: "bold", borderBottom: "2px solid #e9e9e9", paddingBottom: "20px", marginBottom: "30px" }}>Login With Your Account</h4>



          <div class="form-group-registration">
            <div class="form-wrapper-registration">
              <label for="">Mobile Number</label>
              <input style={{ backgroundColor: "#e9e9e9" }} onChange={(e) => setcontact(e.target.value)} id="inputtextareaselectbutton" type="text" class="form-control-registration" />
            </div>

          </div>


          <div class="form-group-registration">
            <div class="form-wrapper-registration">
              <label for="">Password</label>
              <input style={{ backgroundColor: "#e9e9e9" }} onChange={(e) => setPassword(e.target.value)} id="inputtextareaselectbutton" type="password" class="form-control-registration" />
            </div>

          </div>
          {!processing ?

            <button
              disabled={processing && disabled}
              onClick={signIn}
              type="submit"
              className="User__login__signInButton"
            >
              Sign In

            </button>


            :
            <div style={{ display: "flex", justifyContent: "center", margin: "30px" }}>
              <PropagateLoader color="red" width={100}

              />
            </div>

          }




          <div className="newcustomer" style={{ display: "none" }}>
            <h4 style={{ fontWeight: "bold", borderBottom: "2px solid #e9e9e9", marginTop: "20px", paddingBottom: "20px" }}>New Customer</h4>

            <div style={{ width: "85%", fontSize: "20px", textAlign: "justify", wordSpacing: "-0.3px", paddingTop: "20px" }}>
              <p style={{ fontSize: "15px", fontWeight: "700" }}>By Creating An Account With Us.Purchasing On Our Website Becomes Much Faster And Easier </p>

            </div>

          </div>


          <button disabled={processing2 && disabled2} onClick={register} className="User__login__registerButton">

            Sign Up

          </button>
        </div>
      )}





      {inputSelector === 'Register' && placeorder=== false && (
        <div className="User__login__container">
          {history.location.state === undefined ?

            <h1 style={{ fontWeight: "bold", borderBottom: "2px solid #e9e9e9", paddingBottom: "20px", marginBottom: "30px" }}>Register</h1>

            :

            <h3>BILLING DETAILS</h3>


          }


          <div class="form-group-registration">
            <div class="form-wrapper-registration">
              <label for="">First Name</label>
              <input style={{ backgroundColor: "#e9e9e9" }} onChange={(e) => setname(e.target.value)} id="inputtextareaselectbutton" type="text" class="form-control-registration" />
            </div>

          </div>
          {/* <div class="form-group-registration">
            <div class="form-wrapper-registration">
              <label for="">E-mail</label>
              <input style={{ backgroundColor: "#e9e9e9" }} onChange={(e) => setEmail(e.target.value)} id="inputtextareaselectbutton" type="text" class="form-control-registration" />
            </div>

          </div> */}

          <div class="form-group-registration">
            <div class="form-wrapper-registration">
              <label for="">Contact</label>
              <input style={{ backgroundColor: "#e9e9e9" }} onChange={(e) => setcontact(e.target.value)} id="inputtextareaselectbutton" type="text" class="form-control-registration" />
            </div>

          </div>

          <div class="form-group-registration">
            <div class="form-wrapper-registration">
              <label for="">Password</label>
              <input style={{ backgroundColor: "#e9e9e9" }} onChange={(e) => setPassword(e.target.value)} id="inputtextareaselectbutton" type="password" class="form-control-registration" />
            </div>

          </div>

          <div class="form-group-registration">
            <div class="form-wrapper-registration">
              <label for="">Street Address</label>
              <input style={{ backgroundColor: "#e9e9e9" }} onChange={(e) => setaddress(e.target.value)} id="inputtextareaselectbutton" type="text" class="form-control-registration" />
            </div>

          </div>

          <div class="form-group-registration">
            <div class="form-wrapper-registration">
              <label for="">City</label>
              <input style={{ backgroundColor: "#e9e9e9" }} onChange={(e) => setcity(e.target.value)} id="inputtextareaselectbutton" type="text" class="form-control-registration" />
            </div>

          </div>


          {history.location.state === undefined ?
            <button
              onClick={register}
              className={
                inputSelector === 'Register' && 'User__login__signInButton'
              }
            >

              {!processing2 ?

                <span onClick={register}>
                  Sign Up
                </span>
                :
                <span>
                  <PropagateLoader color="white" width={50}

                  />
                </span>
              }
            </button>

            

            :

            <button
              onClick={PlaceorderOpen}
              className={
                inputSelector === 'Register' && 'User__login__signInButton'
              }
            >



              <span onClick={PlaceorderOpen} style={{ fontSize: "11px" }} >
                Sign Up for order
              </span>


            </button>


          }







          <div className="newcustomer" style={{ display: "none" }}>
            <h4 style={{ fontWeight: "bold", borderBottom: "2px solid #e9e9e9", marginTop: "20px", paddingBottom: "20px" }}>Old Customer</h4>

            <div style={{ width: "85%", fontSize: "20px", textAlign: "justify", wordSpacing: "-0.3px", paddingTop: "20px" }}>
              <p style={{ fontSize: "15px", fontWeight: "700" }}>If you have already account?</p>

            </div>

          </div>


          <button disabled={processing2 && disabled2} onClick={signIn}
            type="submit"
            className={
              inputSelector === 'signIn'
                ? 'User__login__signInButton'
                : 'User__login__registerButton'
            }>

            Sign In

          </button>

          {/* <button
            onClick={signIn}
            type="submit"
            className={
              inputSelector === 'signIn'
                ? 'User__login__signInButton'
                : 'User__login__registerButton'
            }
          >
            Sign In
          </button> */}


          {/* order detail  */}


          {/* order detail end */}

        </div>


      )}


    </div>
  )
}

export default Login
