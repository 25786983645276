import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CallIcon from '@material-ui/icons/Call';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import Fade from 'react-reveal/Fade'
import Banner from '../../assets/images/www.png'
import logo3 from "../../assets/icons/logo3.png"

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import './about.css'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    marginTop: '13%',
    marginBottom: "3%",
    backgroundColor: "#56574f",
    borderBottom: "1px solid grey",
    paddingBottom: "30px",
    paddingTop: "30px"
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}))

function About() {
  useEffect(() => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0


  }, [])
  const classes = useStyles()
  return (
    <div class="about-section" style={{ backgroundImage: `url(${Banner})`, backgroundRepeat: "no-repeat" }}>
      <div class="inner-container">
        <h1>About Us</h1>
        <p id="MontserratRegular" class="text">
          ICON WOOD is a Pakistani furniture manufacturer company established in 1988.Our products includes wooden sofas,chairs,bedroom chairs,dinings,beds,center tables and also we can customize according to your need.
          We work with various sorts of furniture, including home and office pieces, and we also perform interior design.
          We also offer customized solutions to enhance the appearance of your furniture.We deal in both iron and
          wood.Practical boards,wood items,and iron products are some of our offerings
        </p>


        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src={logo3} width="25%" />
        </div>

      </div>
    </div>
  )
}

export default About
