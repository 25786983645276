import React, { useState, useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'

import Zoom from 'react-reveal/Zoom';
import Banner1 from "../../../assets/images/banner1.jpg"
import Banner2 from "../../../assets/images/banner2.jpg"
import logo from "../../../assets/icons/logo4.png"

import Loader from "../../loader"





import { Carousel } from "react-bootstrap";


import { useStateValue } from '../../StateProvider'
import { database } from '../../../database'
import styled from "styled-components";
import Media from "../../../shared-components/media"
import './LandingBanner.css'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
    marginTop: '2%',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}))

function LandingBanner() {
  const classes = useStyles()
  const [{ LandingBanners, wishlist, users, bannerloader }, dispatch] = useStateValue()

  useEffect(() => {
    var values = []
    database.ref(`landingbanners`).once('value', (snap) => {
      var fetchData = snap.val()
      for (let keys in fetchData) {
        values.push({ ...fetchData[keys], key: keys })
      }

      values.sort((a, b) => a - b).reverse()

      dispatch({
        type: 'Landing_bannners_DATA',
        payload: values,
        success: true


      })
    })
  }, [LandingBanners])


  return (

    <div className="LandingBannerMain" >

      <Desktop className='desktop'>

        <Carousel pause={false} interval={4000} indicators={false} nextIcon={false} prevIcon={false}  >

          <Carousel.Item>
            < BannerText>
              <Zoom>
                Furnishing Your Dreams

              </Zoom>

            </ BannerText>
            <BannerImage >
              <img
                width="100%"
                src={Banner1}
                alt="home__banner"
              />
            </BannerImage>


          </Carousel.Item>



          <Carousel.Item>
            < BannerText>
              <Zoom>
                Furnishing Your Dreams

              </Zoom>

            </ BannerText>
            <BannerImage >
              <img
                width="100%"
                src={Banner2}
                alt="home__banner"
              />
            </BannerImage>


          </Carousel.Item>

        </Carousel>
      </Desktop>




      <Mobile className='mobile' style={{ display: "none" }}>

        {bannerloader === false ?
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "430px", width: "100%" }}>
            <Loader />
          </div>
          :
          <Carousel pause={false} interval={4000} indicators={false} nextIcon={false} prevIcon={false}  >
            {LandingBanners && LandingBanners.map((item45) => {
              return (
                <Carousel.Item>
                    < BannerText2>
                    <Zoom>
                      Furnishing Your Dreams

                    </Zoom>

                  </ BannerText2>

               

              
                  <BannerImage2 >
                    <img
                      width="100%"
                      src={item45.url}
                      alt="home__banner"
                    />
                  </BannerImage2>

                </Carousel.Item>

              )
            })}
          </Carousel>
        }

      </Mobile>






    </div>

  )
}

const Logo = styled.div`
position: absolute;
left:21%;
top:36%;

`;







const BannerText = styled.div`
position: absolute;
width: 100%;
height: 100%;
background-color:rgba(0, 0, 0, 0.63);
display: flex;
justify-content: center;
align-items: center;
color:white;
font-size: 45px;

${Media("mobile")} {
  width:100%;
font-size: 18px;

}
`;

const BannerImage = styled.div`
width: 100%;
height: 100%;

${Media("mobile")} {
  object-fit:contain

}
`;






const BannerText2 = styled.div`
position: absolute;
width: 100%;
height: 100%;
background-color:rgba(0, 0, 0, 0.63);
display: flex;
justify-content: center;
align-items: center;
color:white;
font-size: 45px;
font-weight:bold;

${Media("mobile")} {
  width:100%;
font-size: 18px;
font-weight:bold;
}
`;

const BannerImage2 = styled.div`

width: 100%;
height: 480px;
object-fit:cover !important;

${Media("mobile")} {
  object-fit:cover !important;

}
`;


















const Desktop = styled.div`
width: 100%;
height: 100%;

${Media("mobile")} {
  object-fit:contain;
  display:none;
}
`;

const Mobile = styled.div`
width: 100%;
height: 100%;
display:none;

${Media("mobile")} {
  object-fit:contain;
      display:flex !important;
}
`;









export default LandingBanner
